import { Link } from 'gatsby'
import * as React from 'react'

const PaginationItem: React.FunctionComponent<PaginationItemProps> = (props: PaginationItemProps) => {
    const { to, children, active } = props

    let link = (
        <Link to={to} className={'page-link'}>
            {children}
        </Link>
    )

    if (active) {
        link = <span className={'page-link'}>{children}</span>
    }

    return <li className={`page-item ${active ? 'active' : ''}`}>{link}</li>
}

export default PaginationItem

interface PaginationItemProps {
    to: string
    active?: boolean
    children: React.ReactNode
}
