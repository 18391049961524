import { Link } from 'gatsby'
import * as React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { formatDate } from '../../../helper/DateHelper'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './PublicationPreview.module.scss'

const PublicationPreview: React.FunctionComponent<PublicationPreviewProps> = (props: PublicationPreviewProps) => {
    const { authorImage, date, author, publicationTitle, teaser, url, t } = props

    const formattedDate = formatDate(date, props.pageContext.language)

    return (
        <Row className={styles.preview}>
            <Col sm={3}>
                <Link to={url}>
                    <Image className={styles.authorImage} src={authorImage} fluid={true} />
                </Link>
            </Col>
            <Col sm={9}>
                <div>
                    <span className={styles.date}>{formattedDate}</span>
                    <span className={styles.author}>
                        {t('template', 'createdby')} {author}
                    </span>
                </div>

                <Link to={url}>
                    <h2>{publicationTitle}</h2>
                </Link>

                <p>{teaser}</p>

                <Link to={url} className={'link-more'}>
                    {t('template', 'general.link.text.readmore')}
                </Link>
            </Col>
        </Row>
    )
}

export default withTranslations(PublicationPreview)

interface PublicationPreviewProps extends Translation {
    pageContext: PageContext<any>
    authorImage: string
    date: string
    author: string
    publicationTitle: string
    teaser: string
    url: string
}
