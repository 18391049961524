import React from 'react'
import { Col, Container, Pagination, Row } from 'react-bootstrap'
import PaginationItem from '../components/content/PaginationItem'
import PublicationPreview from '../components/content/publication/PublicationPreview'
import withTranslations from '../components/hoc/withTranslations'
import * as styles from '../css/PublicationList.module.scss'
import Layout from '../layouts/MainLayout'
import PageContext from '../types/PageContext'
import Translation from '../types/Translation'

class PublicationList extends React.Component<PublicationListProps, PublicationListState> {
    render() {
        const { currentPage, numPages, publicationUrl, templateData } = this.props.pageContext

        const { t } = this.props

        const isFirst = currentPage === 1
        const isLast = currentPage === numPages

        const prevPage = currentPage - 1 === 1 ? publicationUrl : `${publicationUrl}${(currentPage - 1).toString()}`
        const nextPage = `${publicationUrl}${(currentPage + 1).toString()}`

        const lastPage = numPages > 1 ? `${publicationUrl}${numPages}` : publicationUrl

        const renderPublications = templateData.map((publication: any, i: number) => (
            <PublicationPreview
                pageContext={this.props.pageContext}
                key={`publication-${i}`}
                author={publication.author}
                teaser={publication.teaser}
                date={publication.date}
                publicationTitle={publication.title}
                authorImage={publication.authorImage}
                url={publication.url}
            />
        ))

        const paginationItems = Array.from({ length: numPages }, (_, i) => {
            const num = i + 1

            return (
                <PaginationItem to={publicationUrl + (i === 0 ? '' : num)} active={currentPage === num} key={`pagItem-${i}`}>
                    {num}
                </PaginationItem>
            )
        })

        return (
            <Layout pageContext={this.props.pageContext}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h1>{t('template', 'publication.page.title')}</h1>
                            <p>{t('template', 'publication.page.subtitle')}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>{renderPublications.length > 0 && renderPublications}</Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            {renderPublications.length > 0 && (
                                <nav className={styles.paginationContainer}>
                                    <Pagination>
                                        <PaginationItem to={publicationUrl}>{'<<'}</PaginationItem>
                                        {!isFirst && <PaginationItem to={prevPage}>←</PaginationItem>}
                                        {paginationItems}
                                        {!isLast && <PaginationItem to={nextPage}>→</PaginationItem>}
                                        <PaginationItem to={lastPage}>{'>>'}</PaginationItem>
                                    </Pagination>
                                </nav>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Layout>
        )
    }
}

export default withTranslations(PublicationList)

interface PublicationListProps extends Translation {
    pageContext: PageContext<any>
    data: any
}

interface PublicationListState {}
